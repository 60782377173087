<template>
    <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('stat.statistics') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
            <b-list-group>
            <b-list-group-item :to="{ name : 'DownloadStatistic' }">
              <b-avatar variant="light" class="mr-4"><BIconDownload scale="1.3" /></b-avatar>{{ $t('stat.download-statistics') }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container> 
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import { BAvatar, BIconDownload } from "bootstrap-vue";
export default {
  name : "Statistics",
  components: { BAvatar, NavBar, BIconDownload }
}
</script>
<style></style>
